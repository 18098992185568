import React, { FunctionComponent } from 'react';
import { Button as RaButton, useTranslate } from 'react-admin';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Grid as MuiGrid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form as RffForm } from 'react-final-form';
import Input from '../../Input';
import { ModalFormProps } from './types';

const useStyles = makeStyles({
  hide: {
    display: 'none',
  },
});

const ModalForm: FunctionComponent<ModalFormProps> = (props) => {
  const { title, open, handleClose, handleClick, inputs, resource } = props;
  let submit: () => unknown;
  const t = useTranslate();
  const classes = useStyles();

  return (
    <MuiDialog fullWidth open={open} onClose={handleClose}>
      <MuiDialogTitle>{title}</MuiDialogTitle>
      <MuiDialogContent>
        <MuiGrid container>
          <RffForm
            onSubmit={(record) => {
              handleClick(record);
            }}
            resources={resource}
            render={({ handleSubmit, ...restProps }) => {
              submit = handleSubmit;

              return (
                <MuiGrid container spacing={1}>
                  {inputs.map((input) => (
                    <MuiGrid
                      key={input.source}
                      item
                      xs={12}
                      sm={input.width || 12}
                    >
                      <Input
                        {...input}
                        {...restProps}
                        key={input.source}
                        {...(input.hide && { className: classes.hide })}
                        {...(input.multiline && {
                          multiline: true,
                        })}
                        {...(input.width === 12 && {
                          fullWidth: true,
                        })}
                      />
                    </MuiGrid>
                  ))}
                </MuiGrid>
              );
            }}
          />
        </MuiGrid>
      </MuiDialogContent>
      <MuiDialogActions>
        <RaButton
          onClick={() => {
            submit();
          }}
          key="button"
          label={t('button.uppercase.ok')}
        />
        <RaButton onClick={handleClose} label={t('button.uppercase.cancel')} />
      </MuiDialogActions>
    </MuiDialog>
  );
};

export default ModalForm;

import {
  AutocompleteInput as RaAutocompleteInput,
  BooleanInput as RaBooleanInput,
  DateInput as RaDateInput,
  DateTimeInput as RaDateTimeInput,
  SelectInput as RaSelectInput,
  TextInput as RaTextInput,
  ReferenceInput as RaReferenceInput,
  NumberInput as RaNumberInput,
  ImageInput as RaImageInput,
  SelectArrayInput as RaSelectArrayInput,
  ArrayInput as RaArrayInput,
  AutocompleteArrayInput as RaAutocompleteArrayInput,
  ReferenceArrayInput as RaReferenceArrayInput,
} from 'react-admin';
import { FieldType } from '../config/types/field';
import UrlInput from '../components/UrlInput';
import RangeInput from '../components/RangeInput';
import PhoneInput from '../components/PhoneInput';
import TranslatedTextInput from '../components/TranslatedTextInput';
import WysiwygInput from '../components/WysiwygInput';
import CountryInput from '../components/CountryInput';
import TagInput from '../components/TagInput';
import LocationInput from '../components/LocationInput';
import FileInput from '../components/FileInput';

export default class InputFactory {
  private static inputMap = {
    array: RaArrayInput,
    autocomplete: RaAutocompleteInput,
    autocompleteArray: RaAutocompleteArrayInput,
    boolean: RaBooleanInput,
    buttons: () => null,
    company: RaAutocompleteInput,
    country: CountryInput,
    date: RaDateInput,
    dateTime: RaDateTimeInput,
    email: RaTextInput,
    file: FileInput,
    frontOfficeLink: () => null,
    image: RaImageInput,
    location: LocationInput,
    multipleSelect: RaSelectArrayInput,
    number: RaNumberInput,
    phone: PhoneInput,
    range: RangeInput,
    reference: RaReferenceInput,
    referenceArray: RaReferenceArrayInput,
    resourceLink: () => null,
    select: RaSelectInput,
    tag: TagInput,
    text: RaTextInput,
    translatedText: TranslatedTextInput,
    url: UrlInput,
    wysiwyg: WysiwygInput,
    recommendation: () => null,
  };

  static getInputComponent<T extends FieldType>(
    type: T
  ): typeof InputFactory.inputMap[T] {
    if (Object.keys(InputFactory.inputMap).includes(type))
      return InputFactory.inputMap[type];
    throw new Error(`Input type ${type} does not exists`);
  }
}

import React, {
  FunctionComponent,
  useState,
  useEffect,
  createElement,
} from 'react';
import {
  Button as RaButton,
  useTranslate,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { useForm } from 'react-final-form';
import {
  IconButton as MuiIconButton,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  makeStyles,
} from '@material-ui/core';
import { httpClient } from '../../providers/dataProvider';
import { ModalForm } from '../modals';
import { Input as ConfigInput } from '../../config/types/field';
import useConfig from '../../hooks/useConfig';
import { RelatedRecordProps } from './types';
import Field from '../Field';
import { getIconComponentFromName } from '../../helpers/icons';
import { formatCharges } from '../../helpers/relatedRecord';

const useStyles = makeStyles({
  delete: {
    marginLeft: 'auto',
    marginTop: '8px',
    display: 'flex',
    color: '#DC004E',
    backgroundColor: 'rgba(220, 0, 78, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(220, 0, 78, 0.15)',
    },
  },
  edit: {
    marginLeft: 'auto',
    marginTop: '8px',
    display: 'flex',
    color: '#777777',
    backgroundColor: '#E2E2E2',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-start',
  },
  itemCell: {
    padding: '0px 5px',
  },
  table: {
    marginBottom: '20px',
  },
});

const RelatedRecord: FunctionComponent<RelatedRecordProps> = (props) => {
  const config = useConfig();
  const t = useTranslate();
  const form = useForm();
  const record = useRecordContext();
  const classes = useStyles(config.theme);
  const [showDialog, setShowDialog] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const deleteIcon = getIconComponentFromName('Delete');
  const editIcon = getIconComponentFromName('Edit');

  const {
    recordToCreate,
    inputs: inputsItems,
    addRecordLabel,
    filter_resource: filterResource,
    filter_attribute: filterAttribute,
  } = props.relatedRecord;

  function handleClick(values: Record<string, any>) {
    const allItems = [...items];
    if (editMode && selectedItem !== null) {
      allItems[selectedItem] = values;
      setItems(allItems);
      setEditMode(false);
      setSelectedItem(null);
    } else {
      const newItems = [...allItems, values];
      setItems(newItems);
    }
    setShowDialog(false);
  }

  function handleOpenModalClick() {
    setShowDialog(true);
  }

  function handleCloseModalClick() {
    setShowDialog(false);
    setEditMode(false);
    setSelectedItem(null);
  }

  // Change value of the record to create in the form
  function updateForm() {
    form.change(recordToCreate, items);
  }

  function handleEdit(index: number) {
    setEditMode(true);
    setSelectedItem(index);
    handleOpenModalClick();
  }

  function handleDelete(index: number) {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  }

  useEffect(() => {
    updateForm();
  }, [items]);

  const resourceInstance = config.getResource(recordToCreate);

  // Add missing attributes to inputs objects
  const inputs: any[] = (() =>
    Object.entries(inputsItems).map(([key, input]) => ({
      ...resourceInstance.getAttribute(key),
      ...(input as ConfigInput),
      // Add initialValue to edit the selected related record
      ...(editMode &&
        selectedItem !== null && {
          initialValue: items[selectedItem][key],
        }),
      source: key,
    })))();

  const loadItems = () => {
    setLoading(true);
    if (filterAttribute) {
      httpClient(
        `${config.apiUrl}/charges?filter={"${filterResource}_id": "${record.id}"}`
      )
        .then(({ json: data }: any) => {
          // The id and the title of the charges have to be renamed,
          // otherwise react-admin take the id and the title of the contract_milestone
          // because the fields have the same name
          const dataformatted =
            recordToCreate === 'charges' ? formatCharges(data) : data;
          setItems([...dataformatted]);
          setLoading(false);
        })
        .catch(() => {
          notify('notify.error.history', 'error');
        });
    }

    return null;
  };

  useEffect(() => {
    if (filterResource && filterAttribute && record.id) {
      loadItems();
    }
  }, [record.id]);

  return (
    <>
      <RaButton label={t(addRecordLabel)} onClick={handleOpenModalClick} />
      <ModalForm
        open={showDialog}
        title={t('modal.contract_milestone.add_charges')}
        handleClick={handleClick}
        handleClose={handleCloseModalClick}
        inputs={inputs}
        resource={recordToCreate}
      />
      <MuiTable aria-label="Related record table" className={classes.table}>
        <MuiTableBody>
          {items.map((item, index) => (
            <MuiTableRow key={item.title}>
              {inputs.map((input) => {
                // To avoid displaying the id of the charges
                if (input.hide) return null;

                return (
                  <MuiTableCell key={input.source}>
                    <Field
                      key={input.source}
                      record={item}
                      source={input.source}
                      type={input.type}
                      label={input.label}
                      addLabel
                      {...(input.choices && { choices: input.choices })}
                    />
                  </MuiTableCell>
                );
              })}
              <MuiTableCell>
                <MuiIconButton
                  size="small"
                  onClick={() => handleEdit(index)}
                  className={classes.edit}
                >
                  {editIcon && createElement(editIcon)}
                </MuiIconButton>
                {!item.charge_id && (
                  <MuiIconButton
                    size="small"
                    onClick={() => handleDelete(index)}
                    className={classes.delete}
                  >
                    {deleteIcon && createElement(deleteIcon)}
                  </MuiIconButton>
                )}
              </MuiTableCell>
            </MuiTableRow>
          ))}
        </MuiTableBody>
      </MuiTable>
    </>
  );
};

export default RelatedRecord;

import React, { FunctionComponent } from 'react';
import { useInput, useTranslate } from 'react-admin';
import ReactPhoneInput from 'react-phone-input-2';
import { PhoneInputProps } from './types';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import 'react-phone-input-2/lib/material.css';
import useConfig from '../../hooks/useConfig';

const PhoneInput: FunctionComponent<PhoneInputProps> = (
  props: PhoneInputProps
) => {
  const { resource, label } = props;
  const {
    input: { name, onChange, ...restProps },
    isRequired,
  } = useInput(props);
  const t = useTranslate();
  const { defaultCountry, preferredCountries } = useConfig();

  return (
    <ReactPhoneInput
      onChange={(value) => {
        const formattedValue = value[0] === '+' ? value : `+${value}`;
        onChange(formattedValue);
      }}
      country={defaultCountry}
      preferredCountries={preferredCountries}
      specialLabel={t(label)}
      inputProps={{
        name,
        required: isRequired,
      }}
      /* todo update any type */
      isValid={(value, country: { countryCode?: string; iso2?: string }) => {
        const errorMessage = t(`resource.${resource}.${name}.error`);

        try {
          if (!country.countryCode || !country.iso2) {
            if (isRequired) throw new Error(errorMessage);

            return true;
          }

          const valueWithoutCountryCode = value.replace(
            country.countryCode,
            ''
          );
          const countryIso2Code = country.iso2.toUpperCase() as CountryCode;

          return parsePhoneNumber(
            valueWithoutCountryCode,
            countryIso2Code
          ).isValid()
            ? true
            : errorMessage;
        } catch (e) {
          return errorMessage;
        }
      }}
      {...restProps}
      autoFormat={false}
    />
  );
};

export default PhoneInput;

import { Resource as ResourceType } from '../types';

export const milestone: ResourceType = {
  name: 'milestones',
  label: 'resource.milestones.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      label: 'resource.charges.field.id.label',
      type: 'text',
    },
    {
      key: 'created_at',
      label: 'resource.contracts.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.users.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'title',
      label: 'resource.milestones.field.title.label',
      type: 'translatedText',
    },
    {
      key: 'begin_date',
      label: 'resource.milestones.field.begin_date.label',
      type: 'date',
    },
    {
      key: 'end_date',
      label: 'resource.milestones.field.end_date.label',
      type: 'date',
    },
    {
      key: 'for_expert',
      label: 'resource.milestones.field.for_expert.label',
      type: 'boolean',
    },
    {
      key: 'for_client',
      label: 'resource.milestones.field.for_client.label',
      type: 'boolean',
    },
    {
      key: 'units_worked',
      label: 'resource.milestones.field.days_worked.label',
      type: 'number',
    },
    {
      key: 'admin_units_worked',
      label: 'resource.milestones.field.admin_days_worked.label',
      type: 'number',
    },
    {
      key: 'duty_ratio',
      label: 'resource.milestones.field.duty_ratio.label',
      type: 'number',
    },
    {
      key: 'admin_duty_ratio',
      label: 'resource.milestones.field.admin_duty_ratio.label',
      type: 'number',
    },
    {
      key: 'total_duty',
      label: 'resource.milestones.field.total_duty.label',
      type: 'number',
    },
    {
      key: 'admin_total_duty',
      label: 'resource.milestones.field.admin_total_duty.label',
      type: 'number',
    },
    {
      key: 'total_charges',
      label: 'resource.milestones.field.total_charges.label',
      type: 'number',
    },
    {
      key: 'admin_total_charges',
      label: 'resource.milestones.field.admin_total_charges.label',
      type: 'number',
    },
    {
      key: 'total_duty_with_charges',
      label: 'resource.milestones.field.total_duty_with_charges.label',
      type: 'number',
    },
    {
      key: 'admin_total_duty_with_charges',
      label: 'resource.milestones.field.admin_total_duty_with_charges.label',
      type: 'number',
    },
    {
      key: 'description',
      label: 'resource.milestones.field.description.label',
      type: 'text',
    },
    {
      key: 'total_vat',
      label: 'resource.milestones.field.total_vat.label',
      type: 'number',
    },
    {
      key: 'admin_total_vat',
      label: 'resource.milestones.field.admin_total_vat.label',
      type: 'number',
    },
    {
      key: 'total_ttc',
      label: 'resource.milestones.field.total_ttc.label',
      type: 'number',
    },
    {
      key: 'admin_total_ttc',
      label: 'resource.milestones.field.admin_total_ttc.label',
      type: 'number',
    },
    {
      key: 'total_vat_with_charges',
      label: 'resource.milestones.field.total_vat_with_charges.label',
      type: 'number',
    },
    {
      key: 'admin_total_vat_with_charges',
      label: 'resource.milestones.field.admin_total_vat_with_charges.label',
      type: 'number',
    },
    {
      key: 'total_ttc_with_charges',
      label: 'resource.milestones.field.total_ttc_with_charges.label',
      type: 'number',
    },
    {
      key: 'admin_total_ttc_with_charges',
      label: 'resource.milestones.field.admin_total_ttc_with_charges.label',
      type: 'number',
    },
    {
      key: 'kind',
      type: 'text',
    },
    {
      key: 'proposal_id',
      type: 'reference',
      reference: 'proposals',
    },
    {
      key: 'mission_id',
      type: 'reference',
      reference: 'missions',
    },
    {
      key: 'owner_id',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'client_owner_id',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'status',
      label: 'resource.proposals.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'draft',
          name: 'resource.proposals.field.status.choices.draft.label',
        },
        {
          id: 'review_admin',
          name: 'resource.proposals.field.status.choices.review_admin.label',
        },
        {
          id: 'review_client',
          name: 'resource.proposals.field.status.choices.review_client.label',
        },
        {
          id: 'request_for_update',
          name: 'resource.proposals.field.status.choices.request_for_update.label',
        },
        {
          id: 'refused',
          name: 'resource.proposals.field.status.choices.refused.label',
        },
        {
          id: 'accepted',
          name: 'resource.proposals.field.status.choices.accepted.label',
        },
        {
          id: 'archived',
          name: 'resource.proposals.field.status.choices.archived.label',
        },
      ],
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      hasCreateButton: false,
      fields: [],
    },
    create: {
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.milestones.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.milestones.section.information.title',
                layout: [
                  ['quotation_id'],
                  ['title'],
                  ['description'],
                  ['begin_date', 'end_date'],
                  ['for_expert', 'for_client'],
                  ['units_worked', 'admin_units_worked'],
                  ['duty_ratio', 'admin_duty_ratio'],
                ],
                inputs: {
                  quotation_id: {
                    type: 'text',
                    disabled: true,
                  },
                  title: {
                    validators: [
                      { key: 'required' },
                      { key: 'maxLength', value: 50 },
                    ],
                  },
                  description: {
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  begin_date: {
                    validators: [{ key: 'required' }],
                    width: 6,
                  },
                  end_date: {
                    validators: [{ key: 'required' }],
                    width: 6,
                  },
                  for_expert: {
                    validators: [{ key: 'required' }],
                    width: 6,
                    initialValue: true,
                  },
                  for_client: {
                    validators: [{ key: 'required' }],
                    width: 6,
                    initialValue: true,
                  },
                  units_worked: {
                    width: 6,
                    validators: [
                      { key: 'required' },
                      { key: 'minValue', value: 0 },
                    ],
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  admin_units_worked: {
                    width: 6,
                    validators: [
                      { key: 'required' },
                      { key: 'minValue', value: 0 },
                    ],
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  duty_ratio: {
                    width: 6,
                    extensions: [
                      {
                        key: 'multiplyByHundredInput',
                      },
                    ],
                    validators: [
                      { key: 'required' },
                      { key: 'minValue', value: 0 },
                      { key: 'maxValue', value: 100 },
                    ],
                    options: {
                      min: 0,
                      max: 100,
                      step: 0.001,
                    },
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  admin_duty_ratio: {
                    width: 6,
                    extensions: [
                      {
                        key: 'multiplyByHundredInput',
                      },
                    ],
                    validators: [
                      { key: 'required' },
                      { key: 'minValue', value: 0 },
                      { key: 'maxValue', value: 100 },
                    ],
                    options: {
                      min: 0,
                      max: 100,
                      step: 0.001,
                    },
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'charges',
                title: 'resource.milestones.tab.charges.title',
                layout: [['charges']],
                inputs: {},
                rules: {
                  hide: [
                    {
                      property: 'kind',
                      op: 'eq',
                      value: 'forfeit',
                    },
                  ],
                },
                relatedRecord: {
                  recordToCreate: 'charges',
                  addRecordLabel: 'action.add_related_charges',
                  filter_resource: 'milestone',
                  filter_attribute: 'id',
                  inputs: {
                    charge_title: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    unit: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    admin_quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                    admin_cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                  },
                },
              },
            ],
          },
        ],
      },
    },
    edit: {
      deleteButton: {
        confirmation: {
          content: 'resource.milestone.delete.button.content',
        },
        disabled: [
          {
            property: 'status',
            op: 'includes',
            value: ['accepted', 'refused', 'archived'],
          },
        ],
        redirect: {
          page: 'edit',
          basePath: '/proposals',
          recordId: 'proposal_id',
        },
        label: 'resource.milestone.delete.button.label',
      },
      singleRedirect: {
        page: 'edit',
        basePath: '/proposals',
        recordId: 'proposal_id',
      },
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.milestones.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.milestones.section.information.title',
                layout: [
                  ['title'],
                  ['description'],
                  ['begin_date', 'end_date'],
                  ['for_expert', 'for_client'],
                  ['units_worked', 'admin_units_worked'],
                  ['duty_ratio', 'admin_duty_ratio'],
                  ['total_duty', 'admin_total_duty'],
                  ['total_vat', 'admin_total_vat'],
                  ['total_ttc', 'admin_total_ttc'],
                  ['total_duty_with_charges', 'admin_total_duty_with_charges'],
                  ['total_vat_with_charges', 'admin_total_vat_with_charges'],
                  ['total_ttc_with_charges', 'admin_total_ttc_with_charges'],
                ],
                inputs: {
                  title: {
                    validators: [
                      { key: 'required' },
                      { key: 'maxLength', value: 50 },
                    ],
                    rules: {
                      disabled: [
                        {
                          reference: {
                            resource: 'proposals',
                            key: 'proposal_id',
                          },
                          property: 'status',
                          op: 'not_includes',
                          value: [
                            'draft',
                            'review_admin',
                            'review_client',
                            'request_for_update',
                          ],
                        },
                      ],
                    },
                  },
                  description: {
                    rules: {
                      disabled: [
                        {
                          reference: {
                            resource: 'proposals',
                            key: 'proposal_id',
                          },
                          property: 'status',
                          op: 'not_includes',
                          value: [
                            'draft',
                            'review_admin',
                            'review_client',
                            'request_for_update',
                          ],
                        },
                      ],
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  begin_date: {
                    validators: [{ key: 'required' }],
                    width: 6,
                    rules: {
                      disabled: [
                        {
                          reference: {
                            resource: 'proposals',
                            key: 'proposal_id',
                          },
                          property: 'status',
                          op: 'not_includes',
                          value: [
                            'draft',
                            'review_admin',
                            'review_client',
                            'request_for_update',
                          ],
                        },
                      ],
                    },
                  },
                  end_date: {
                    validators: [{ key: 'required' }],
                    width: 6,
                    rules: {
                      disabled: [
                        {
                          reference: {
                            resource: 'proposals',
                            key: 'proposal_id',
                          },
                          property: 'status',
                          op: 'not_includes',
                          value: [
                            'draft',
                            'review_admin',
                            'review_client',
                            'request_for_update',
                          ],
                        },
                      ],
                    },
                  },
                  for_expert: {
                    validators: [{ key: 'required' }],
                    width: 6,
                    rules: {
                      disabled: [
                        {
                          reference: {
                            resource: 'proposals',
                            key: 'proposal_id',
                          },
                          property: 'status',
                          op: 'not_includes',
                          value: [
                            'draft',
                            'review_admin',
                            'request_for_update',
                          ],
                        },
                      ],
                    },
                  },
                  for_client: {
                    validators: [{ key: 'required' }],
                    width: 6,
                    rules: {
                      disabled: [
                        {
                          reference: {
                            resource: 'proposals',
                            key: 'proposal_id',
                          },
                          property: 'status',
                          op: 'not_includes',
                          value: [
                            'draft',
                            'review_admin',
                            'request_for_update',
                          ],
                        },
                      ],
                    },
                  },
                  units_worked: {
                    width: 6,
                    validators: [
                      { key: 'required' },
                      { key: 'minValue', value: 0 },
                    ],
                    rules: {
                      disabled: [
                        {
                          reference: {
                            resource: 'proposals',
                            key: 'proposal_id',
                          },
                          property: 'status',
                          op: 'not_includes',
                          value: [
                            'draft',
                            'review_admin',
                            'review_client',
                            'request_for_update',
                          ],
                        },
                      ],
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  admin_units_worked: {
                    width: 6,
                    validators: [
                      { key: 'required' },
                      { key: 'minValue', value: 0 },
                    ],
                    rules: {
                      disabled: [
                        {
                          reference: {
                            resource: 'proposals',
                            key: 'proposal_id',
                          },
                          property: 'status',
                          op: 'not_includes',
                          value: [
                            'draft',
                            'review_admin',
                            'review_client',
                            'request_for_update',
                          ],
                        },
                      ],
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  duty_ratio: {
                    width: 6,
                    extensions: [
                      {
                        key: 'multiplyByHundredInput',
                      },
                    ],
                    validators: [
                      { key: 'required' },
                      { key: 'minValue', value: 0 },
                      { key: 'maxValue', value: 100 },
                    ],
                    options: {
                      min: 0,
                      max: 100,
                      step: 0.001,
                    },
                    rules: {
                      disabled: [
                        {
                          reference: {
                            resource: 'proposals',
                            key: 'proposal_id',
                          },
                          property: 'status',
                          op: 'not_includes',
                          value: [
                            'draft',
                            'review_admin',
                            'review_client',
                            'request_for_update',
                          ],
                        },
                      ],
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  admin_duty_ratio: {
                    width: 6,
                    extensions: [
                      {
                        key: 'multiplyByHundredInput',
                      },
                    ],
                    validators: [
                      { key: 'required' },
                      { key: 'minValue', value: 0 },
                      { key: 'maxValue', value: 100 },
                    ],
                    options: {
                      min: 0,
                      max: 100,
                      step: 0.001,
                    },
                    rules: {
                      disabled: [
                        {
                          reference: {
                            resource: 'proposals',
                            key: 'proposal_id',
                          },
                          property: 'status',
                          op: 'not_includes',
                          value: [
                            'draft',
                            'review_admin',
                            'review_client',
                            'request_for_update',
                          ],
                        },
                      ],
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  total_duty: {
                    width: 6,
                    disabled: true,
                    extensions: [
                      {
                        key: 'modifiedLabel',
                      },
                    ],
                  },
                  admin_total_duty: {
                    width: 6,
                    disabled: true,
                    extensions: [
                      {
                        key: 'modifiedLabel',
                      },
                    ],
                  },
                  total_vat: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  admin_total_vat: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  total_ttc: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  admin_total_ttc: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  total_duty_with_charges: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  admin_total_duty_with_charges: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  total_vat_with_charges: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  admin_total_vat_with_charges: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  total_ttc_with_charges: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  admin_total_ttc_with_charges: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'charges',
                title: 'resource.milestones.tab.charges.title',
                layout: [],
                inputs: {},
                rules: {
                  hide: [
                    {
                      property: 'kind',
                      op: 'eq',
                      value: 'forfeit',
                    },
                  ],
                },
                relatedRecord: {
                  recordToCreate: 'charges',
                  addRecordLabel: 'action.add_related_charges',
                  filter_resource: 'milestone',
                  filter_attribute: 'id',
                  inputs: {
                    charge_id: {
                      disabled: true,
                      width: 12,
                      hide: true,
                    },
                    charge_title: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    unit: {
                      validators: [{ key: 'required' }],
                      width: 6,
                    },
                    quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    admin_quantity: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                      step: 0.5,
                    },
                    cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                    admin_cost_per_unit: {
                      validators: [
                        { key: 'required' },
                        {
                          key: 'minValue',
                          value: 0,
                        },
                      ],
                      width: 6,
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.charges.section.details.title',
              layout: [
                ['id'],
                ['created_at', 'updated_at'],
                ['proposal_id'],
                ['mission_id'],
                ['owner_id'],
                ['owner_company'],
                ['client_owner_id'],
                ['client_owner_company'],
              ],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
              },
              fields: {
                proposal_id: {
                  addLabel: true,
                  label: 'resource.proposals.field.proposal_id.label',
                  child: {
                    source: 'proposal.guid',
                    type: 'text',
                  },
                },
                mission_id: {
                  addLabel: true,
                  label: 'resource.proposals.field.mission_id.label',
                  child: {
                    source: 'mission.name',
                    type: 'text',
                  },
                },
                owner_id: {
                  addLabel: true,
                  label: 'resource.proposals.field.candidacy_owner_name.label',
                  child: {
                    source: 'user.full_name',
                    type: 'text',
                  },
                },
                owner_company: {
                  source: 'owner_id',
                  addLabel: true,
                  link: false,
                  label: 'resource.users.field.company_name_profile.label',
                  child: {
                    source: 'user.company_name',
                    type: 'text',
                  },
                },
                client_owner_id: {
                  addLabel: true,
                  label: 'resource.candidacies.field.company_name.label',
                  child: {
                    source: 'user.full_name',
                    type: 'text',
                  },
                },
                client_owner_company: {
                  source: 'client_owner_id',
                  addLabel: true,
                  link: false,
                  label: 'resource.missions.field.company_id.label',
                  child: {
                    source: 'user.company_name',
                    type: 'text',
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
